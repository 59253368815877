
<template>
  <div>
    <div class="contents-one uk-inline">
      <img :src="imgSrc" alt="" >
      <div class="uk-height-1-1 uk-width-1-4@m uk-width-1-2@s uk-padding-remove uk-position-right right-bar" >
        <template v-for="(info, pageName, i) of getPageNames"  >
          <div :key="i" :style="{ height:100/getPageNamesSize + '%'}" @mouseover="hoverOn(pageName)" @mouseout="hoverOff">
            <router-link  :to="{name:pageName}" :target="info.target">
              <div class="uk-card uk-card-body " >
                {{$_chlang(info.title)}}
                <a class="uk-position-absolute icon-style" href="#" uk-icon="icon:chevron-right; ratio: 2"></a>
              </div>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  components: {
  },
  data: () => ({
    imageName : '',
    defultImage:"sub-links-jigyo-default.jpg",
  }),
  methods: {
    hoverOn(image) {
      this.imageName = "sub-links-jigyo-" + image + ".jpg";
    },
    hoverOff () {
      this.imageName = '';
    }
  },
  computed: {
    imgSrc () {
      if(this.imageName != ''){
        return require('@/assets/img/image/' + this.imageName)  
      }
      return require('@/assets/img/image/' + this.defultImage)  
    },
    getPageNames() {
      return this.$_menu(this.commonSetting.jigyoMenuList);
    },
    getPageNamesSize() {
      return Object.keys(this.getPageNames).length;
    },
  }
}
</script>



<style scoped lang="scss">
img {
  
  object-fit: cover;
  width: 100%;
  height: 100%
}
a{
  text-decoration: none;
}
.uk-card {
  background:rgba(0, 0, 0, 0.5);
  height:99%;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 3px #272727;
  border: none;
  font-size: 1.2rem;
 
  .icon-style{
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
}
.contents-one {
  width:100%;
}
.right-bar > div:last-child > .uk-card {
  height:100%;
}

/* フェードイン・フェードアウト中の設定 */
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
/* フェードイン開始時・フェードアウト終了時の設定 */
.fade-enter, .fade-leave-to {
    opacity: 0;
}

</style>
