
<template>

  <div>
    <div class="contents">
      <div class=" uk-background-center-center parallax-bg" >
        <h2 class="uk-text-center page-title uk-padding-small">
          Access<br>
          アクセス
        </h2>
        <div v-for="(office, i) in offices" :key="i" class="access-list uk-padding uk-child-width-1-2@l uk-child-width-1-1@s uk-grid" >
          <div>
            <div class="name">{{$_chlang(office.name)}}</div>
            <div class="address-info uk-margin-top ">
              <div v-for="(item, key, j) in pageItems" :key="j" uk-grid>
                  <div class="uk-width-1-4" uk-leader>{{$_chlang(item)}}</div>
                  <div class="uk-width-3-4">{{$_chlang(office[key])}}</div>
              </div>
            </div>
          </div>
          <div>
              <iframe :src="office.map" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CompanyAddress from '../../mixins/company-address.yaml'

export default {
  name: 'Access',
  data: () => ({
    pageItems: [],
    offices: [],
  }),
  
  created() {
    this.pageItems = CompanyAddress.pageItems;
    this.offices = CompanyAddress.addressItems;
  },
}
</script>



<style scoped lang="scss">
.parallax-bg {
  background-image:url(~@/assets/img/image/page-company-access.png);
}
.access-list{
  min-height: 30vh;
  color: rgb(255, 255, 255);
  text-shadow:
    -1px -1px 1px #3f3f3f,  
    1px -1px 1px #3f3f3f,
    -1px 1px 1px #3f3f3f,
    1px 1px 1px #3f3f3f; /* 黒色の縁取り */
  
  .name{
    text-indent: 1rem;
    font-size: 1.5rem;
  }
  .uk-grid{
    margin: 1%;
    div{
      padding: 0.5rem;
    }
  }
  *{
    max-height: 100%;
    max-width: 100%;
  }
}
</style>
