import CommonSetting from './common-setting.yaml'


export default {
  data () {
    return {
      commonSetting:CommonSetting,
    }
  },
  methods: {
    $_objectListSearch(items,keyval,keyname="title",val="detail") {
      for(const item  of items ){
        if( item[keyname] == keyval){
          return item[val]
        }
      }
      return "";
    },
    // I18n用メッセージ
    $_chlang(items) {
      if (!items) return '';
      if (typeof items === 'string'  )  return items;
      if (items[this.$i18n.locale]  !== undefined ) return items[this.$i18n.locale]; 
      return items["ja"];
    },
    // key:router名 :{ title: 表示名, path: 表示パス}
    $_menu: function (menues){
      const menuList = {};
      if (menues[this.$i18n.locale]  !== undefined )  menues = menues[this.$i18n.locale];
      for(const menu of menues){
        const routeObj = routerObj( menu, this.$router.options.routes);
        menuList[menu] = {
          title:routeObj.meta.title,
          path:routeObj.path,
          target:(routeObj.meta.target!==undefined) ? routeObj.meta.target:''
        };
      }
      return menuList;
    },
    // 現在ページのタイトル取得
    $_documentTitle(){
      return this.$_chlang(this.$route.meta.title);
    },
    // 現在ページのdescription取得
    $_documentDescription(){
      return this.$_chlang(this.$route.meta.desc);
    },
    $_isJapanese(){
      return this.$i18n.locale == 'ja';
    },
    // 年月英語変換
    $_dateChange(dateObj){
      if(this.$i18n.locale == 'ja') return dateObj;
      let year = dateObj.substring(0,4);
      let month = dateObj.substring(5,7);
      return this.commonSetting.months[parseInt(month)-1] + " " + year;
    },
    $_apiGet: function(url,func){
      this.axios.get(process.env.VUE_APP_API_URL + url)
          .then(func)
          .catch((e) => { console.log(e); });    
    },
    $_apiPost: function(request, url, func){
      console.log(process.env.VUE_APP_API_URL + url)
      this.axios.post(process.env.VUE_APP_API_URL + url,request)
          .then(func)
          .catch((e) => { console.log(e); });    
    },
  }
}

// ルータオブジェクトから単一のルート情報取得
function routerObj(pageName,routeres) {
  const pages = routeres;
  for(const page of pages){
    if(page.name === pageName) return page;
  }
  return "";
}