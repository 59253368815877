<template>

  <div>
    <div class="contents">
      <div class="uk-background-center-center parallax-bg" >
        <h2 class="uk-text-center page-title uk-padding-small">
          Company<br>
          会社案内
        </h2>
        <div class="section-title">
          <span >{{$_chlang(greetings.title)}}</span>
        </div>
        <div class="section-main">
          <span>
            {{$_chlang(greetings.detail)}}
          </span>
        </div>
        <div class="section-title">
          <span >{{$_chlang(profiles.title)}}</span>
        </div>
        <div class="section-main">
          <table class="uk-table uk-table-justify uk-table-divider ">
            <tbody>
              <tr v-for="(profile, i) in profiles.detail" :key="i">
                <th class="uk-width-small uk-text-top">{{$_chlang(profile.title)}}</th>
                <td class="uk-table-expand">{{$_chlang(profile.detail)}}</td>
              </tr>
              <tr>
                <th class="uk-text-top">{{$_chlang(businessContents.title)}}</th>
                <td >
                  <ul class="uk-list uk-list-decimal">
                    <li v-for="(list, i) in businessContents.detail" :key="i" >
                      {{$_chlang(list.text)}}
                      <div v-if="isArray(list)" class="uk-margin-top">
                        <ul class="uk-list uk-list-hyphen">
                          <li v-for="(sublist, j) in list.list" :key="j">{{$_chlang(sublist)}}</li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr v-if="this.$i18n.locale == 'ja'">
                <th class="uk-text-top">{{banks.title}}</th>
                <td class="uk-column-1-2@s uk-column-1-3@m uk-column-1-4@l uk-column-1-5@xl">
                  <p v-for="(bank, i) in banks.detail" :key="i" >{{bank}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="section-title">
          <span >{{$_chlang(histories.title)}}</span>
        </div>
        <div class="section-main">
          <table class="uk-table uk-table-justify uk-table-divider " >
            <tbody>
              <tr v-for="(history, i) in histories.detail" :key="i" >
                <th class="uk-width-small uk-text-top">
                  {{$_dateChange(history.title)}}</th>
                <td class="uk-table-expand">{{$_chlang(history.detail)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CompanyInfo from '../../mixins/company-info.yaml'

export default {
  name: 'CompanyInfo',
  data: () => ({
    greetings: [],
    profiles: [],
    businessContents: [],
    banks: [],
    histories: [],
  }),
  created() {
    this.greetings = CompanyInfo.greeting,
    this.profiles = CompanyInfo.profile,
    this.businessContents = CompanyInfo.businessContent,
    this.banks = CompanyInfo.bank,
    this.histories = CompanyInfo.history
  },
  methods: {
    isArray(lis) {
      return Array.isArray(lis.list);
    },
  }

}
</script>



<style scoped lang="scss">
.parallax-bg {
  background-image:url(~@/assets/img/image/page-company-info.jpg);
}

th{
  color: rgb(63, 63, 63);
  text-transform: none;
}

h2{
  color: #fff;
}
.uk-width-small{
  @media screen and (max-width: 640px) {
    width: 100px;
  }
}
span{
  white-space: pre-line;
}

</style>
