<template>
  <div>
    <footer class="uk-light uk-padding-small" >
      <div class="uk-child-width-1-2 uk-text-left" uk-grid>
        <div class="company-name">{{$_chlang(companyName)}}</div>
      </div>
      <div class=" uk-text-left" uk-grid>
        <div class="uk-width-2-3@m">
          <table class="uk-table ">
              <tbody class="uk-hidden@m" >
                <div class="uk-padding-remove-vertical " v-for="(office, i) in offices" :key="i">
                  <tr > <td style="font-size: 1.1rem; font-weight: 600;" >{{$_chlang(office.name)}}</td></tr>
                  <tr > <td class="uk-padding-remove-vertical ">{{$_chlang(office.address)}}</td> </tr>
                  <tr > <td class="uk-padding-remove-vertical ">{{'TEL ' + office.tel}}</td> </tr>
                  <tr > <td class="uk-padding-remove-vertical ">{{'FAX ' + office.fax}}</td> </tr>
                </div>
              </tbody>
              <tbody class="uk-visible@m">
                  <tr v-for="(office, i) in offices" :key="i">
                    <td class="uk-padding-remove-vertical ">{{$_chlang(office.name)}}</td>
                    <td class="uk-padding-remove-vertical ">{{$_chlang(office.address)}}</td>
                    <td class="uk-padding-remove-vertical " style="font-size:0.8rem;">{{' TEL ' + office.tel + ' FAX ' + office.fax}}</td>
                  </tr>
              </tbody>
          </table>
        </div>
        <div class=" uk-text-right uk-width-1-3@m">
          <!-- <span class="uk-margin-right" uk-icon="icon:facebook; ratio: 3"></span>
          <span class="uk-margin-right" uk-icon="icon:instagram; ratio: 3"></span> -->
        </div>
      </div>
      <div class=" uk-text-right ">
        © 2010-2022 WORLDing Co., Limited.   
      </div>
    </footer>
  </div>
</template>


<script>
import CompanyAddress from '../../../mixins/company-address.yaml'

export default {
  data: () => ({
    offices: [],
    companyName: {},
  }),
  
  created() {
    this.offices = CompanyAddress.addressItems;
    this.companyName = CompanyAddress.companyName;
  },
}
</script>



<style scoped lang="scss">
footer {
  background-color: var(--main-color);
  position: sticky;/*固定する*/
  position: -webkit-sticky;/*Safari用ベンダープレフィックス*/
  bottom: 0;
  z-index: 80;
}

.company-name{
  font-size: 1.4rem;
  font-weight: 600;
}
</style>
