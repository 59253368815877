<template>

  <div>
    <div class="uk-position-center uk-overlay uk-overlay-default" style="text-align: center">
      <div >
        <worlding-icon class="worlding-icon " />
      </div>
      <div>
        <top-center-moji  class="moji-brightyour-business uk-padding-small" />
      </div>
      <div>
        <worlding-moji  class="worlding-moji uk-padding-small" />
      </div>
    </div>
  </div>
  
</template>


<script>
import WorldingIcon from "@/assets/img/WorldingIcon.svg"
import TopCenterMoji from "@/assets/img/TopCenterMoji.svg"
import WorldingMoji from "@/assets/img/WorldingMoji.svg"

export default {
  components: {
    WorldingIcon,
    WorldingMoji,
    TopCenterMoji,
  },
}
</script>



<style scoped lang="scss">

@-webkit-keyframes icon-parts {
  0%   { opacity: 0;}
  20%  { opacity: 0;}
  60%  { -webkit-transform: translateY(0); opacity: 1;}
  75%  { -webkit-transform: scale(1.0, 1.0) translate(0%, 0%); }
  80%  { -webkit-transform: scale(0.95, 1.1) translate(0%, -8%); }
  85%  { -webkit-transform: scale(0.95, 1.1) translate(0%, -5%); }
  90%  { -webkit-transform: scale(1.1, 0.8) translate(0%, 5%); }
  95%  { -webkit-transform: scale(1.0, 1.0) translate(0%, 0%); }
  100% { -webkit-transform: translateY(0);}
}

@keyframes icon-parts {
  0%   { opacity: 0;}
  20%  { opacity: 0;}
  60%  { transform: translateY(0); opacity: 1;}
  75%  { transform: scale(1.0, 1.0) translate(0%, 0%); }
  80%  { transform: scale(0.95, 1.1) translate(0%, -8%); }
  85%  { transform: scale(0.95, 1.1) translate(0%, -5%); }
  90%  { transform: scale(1.1, 0.8) translate(0%, 5%); }
  95%  { transform: scale(1.0, 1.0) translate(0%, 0%); }
  100% { transform: translateY(0);}
}
svg.worlding-icon {
  width:20vh;
  path.right-mark{
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
}

svg.worlding-icon path {
  /** アニメーション  name | 所要時間 | 減速⇒高速  */
  -webkit-animation:  icon-parts  4s ease-in;
  animation:  icon-parts  4s ease-in;
  transform-origin:center;
}

@keyframes text-parts {
  0%   { opacity: 0;}
  20%  { opacity: 0;}
  60%  { opacity: 1;}
  100% { opacity: 1;}
}
svg.moji-brightyour-business {
  width:50%;
}
svg.worlding-moji {
  width:100%;
}
svg.worlding-moji path, svg.moji-brightyour-business path {
  /** アニメーション  name | 所要時間 | 減速⇒高速  */
  -webkit-animation:  text-parts  4s ease-in;
  animation:  text-parts  4s linear;
  transform-origin:center;
}


</style>
