<template>
  <div class="switchArea " >
    <input type="checkbox" id="switch1" @change="$emit('child-event',$event)" >
    <label for="switch1"><span ></span></label>
    <div id="swImg"></div>
  </div>
</template>

<script>

export default {
  mounted: function () {
    if (navigator.language.split("-")[0] !== 'ja' && !document.getElementById('switch1').checked){
      document.getElementById('switch1').click();
    }
  },  
}
</script>

<style scoped lang="scss" >
$_off: 'English';
$_on: '日本語';

 /* === ボタンを表示するエリア ============================== */
.switchArea {
  $_font  : 0.8rem;
  $_padding  : 1.2rem;
  $_line-height : $_font + $_padding;
  $_width : $_font * 7;
  

  $_maru-size :$_line-height*0.75;
  $_maru-pt :  ($_line-height - $_maru-size) /2;

  line-height    : $_line-height;                /* 1行の高さ          */
  letter-spacing : 0;                   /* 文字間             */
  text-align     : center;              /* 文字位置は中央     */
  font-size      : $_font;                /* 文字サイズ         */
  position       : relative;            /* 親要素が基点       */
  margin         : auto;                /* 中央寄せ           */
  width          : $_width;               /* ボタンの横幅       */
  background     : #fff;                /* デフォルト背景色   */
  
  /* === チェックボックス ==================================== */
  input[type="checkbox"] {
    display        : none;            /* チェックボックス非表示 */
  }
  /* === チェックボックスのラベル（標準） ==================== */
  label {
    display        : block;               /* ボックス要素に変更 */
    box-sizing     : border-box;          /* 枠線を含んだサイズ */
    height         : $_line-height;       /* ボタンの高さ       */
    border         : 2px solid #999999;   /* 未選択タブのの枠線 */
    border-radius  : $_line-height/2;                /* 角丸               */

    /* === 表示する文字（標準） ================================ */
    span:after{
      content        : $_off;              /* 表示する文字       */
      padding        : 0 0 0 $_padding;          /* 表示する位置       */
      color          : #999999;             /* 文字色             */
    }
  }
  /* === チェックボックスのラベル（ONのとき） ================ */
  input[type="checkbox"]:checked +label {
    border-color   : #78bd78;             /* 選択タブの枠線     */

    /* === 表示する文字（ONのとき） ============================ */
    span:after{
      content        : $_on;                 /* 表示する文字       */
      padding        : 0  $_padding 0 0;          /* 表示する位置       */
      color          : #78bd78;             /* 文字色             */
    }
  }
  
  /* === 丸部分のSTYLE（標準） =============================== */
  #swImg {
    position       : absolute;            /* 親要素からの相対位置*/
    width          : $_maru-size;                /* 丸の横幅           */
    height         : $_maru-size;                /* 丸の高さ           */
    background     : #999999;             /* カーソルタブの背景 */
    top            : $_maru-pt;                 /* 親要素からの位置   */
    left           : $_maru-pt;                  /* 親要素からの位置   */
    border-radius  : $_maru-size/2;                /* 角丸               */
    transition     : .2s;                 /* 滑らか変化         */
  }

  /* === 丸部分のSTYLE（ONのとき） =========================== */
  input[type="checkbox"]:checked ~ #swImg {
    transform      : translateX($_width - $_maru-size - ($_maru-pt*2));    /* 丸も右へ移動       */
    background     : #78bd78;             /* カーソルタブの背景 */
  }

}

</style>