<template>
  <div class="contents-one" >
    <p class="desc">{{$_chlang(this.commonSetting.defaultDesc)}}</p>
    <div class="uk-child-width-1-1 message-top" uk-grid>
        <div class="uk-position-relative uk-visible-toggle uk-light" loop tabindex="-1" uk-slideshow="animation: pull; autoplay: true; ratio: false; autoplayInterval:4000">
            <ul class="uk-slideshow-items" style="height:60vh;" >
                <li v-for="n in 6" :key="n" >
                    <div class="uk-position-cover uk-animation-kenburns uk-transform-origin-bottom-right">
                      <img :src="require('@/assets/img/image-top/top-image'+ n + '.jpg')" alt=""  uk-cover>
                    </div>
                </li>
            </ul>
            <div style=" margin-bottom:1vh;" v-if="specialTopic.notes" class="uk-overlay uk-overlay-primary uk-position-bottom-right uk-position-small">
                <h3 class="uk-margin-remove">{{specialTopic.viewDate}}</h3>
                <p class="uk-margin-remove">{{specialTopic.notes}}</p>
            </div>
            <div class="massage ">
              <span>{{environmentMessage}}</span>
            </div>
        </div>
    </div>
    <div v-if="getTopicSize > 0"  class="topic"   >
        <div class="list" >
          <div  style="margin-bottom:1rem" v-for="(list, i) in lists" :key="i" >
              <span>{{list.viewDate + ' .........'}}</span>
              <span style="margin:0 1rem">{{list.notes}}</span>
              <a v-if="list.url.length > 5" :href="list.url" ><span uk-icon="link"></span></a>
          </div>
        </div>
        <div class="scrolldown3 uk-visible@s"><span>Scroll</span></div>
    </div>
    <mini-manu />
    
  </div>
</template>


<script>
import CompanyInfo from '../../mixins/company-info.yaml'
import miniManu from'../modules/parts/MiniMenu.vue'

export default {
  components: {
    miniManu
  },
  data() {
    return {
      lists: [],
      specialTopic:{},
      environmentMessage:'',
    };
  },
  computed : {
    isSpecialTopic(){
      return Object.keys(this.specialTopic).length > 0;
    },
    getTopicSize(){
      return this.lists.length > 4 ? 4 : this.lists.length;
    }
  },

  created() {
    this.listUpdate();
    this.environmentMessage = this.$_chlang(CompanyInfo.environmentMessage);
  },
  methods: {
    listUpdate: function(){
      this.$_apiGet('/topic/list-worlding',(response) => { this.lists = response.data; });
      this.$_apiGet('/topic/list-worlding/specal',(response) => { this.specialTopic = response.data; });
    },

  }
}
</script>



<style scoped lang="scss">
.contents {
  height:90vh;
  min-height:90vh;
}

.message-top {  
    position: relative;
    .massage {
        position: absolute;
        // width: 70%;
        top: 2vh;
        // bottom: 2vh;
        // left: 2vw;
        z-index:50;
        background: rgba(0, 0, 0, 0.4);

        span{
          display:inline-block;
          margin:3%;
          white-space: pre-line;
          color: rgb(255, 250, 203);
          text-shadow: 2px 2px 1px #161616;
        }
    }
}

.topic {
  margin: 6vh 10vw 2vh ;
  padding: 0.5vh 1vw;
  border: solid 2px #32363e;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
  border-radius: 0 5px 5px 5px; 
  position: relative;
  height:12rem;
  
  .list{
    position: relative;
    overflow-y: scroll;
    height:100%;

      &::-webkit-scrollbar{
        width: 10px;
      }
      &::-webkit-scrollbar-track{
        background: #fff;
        border: none;
        border-radius: 10px;
        box-shadow: inset 0 0 2px #777; 
      }
      &::-webkit-scrollbar-thumb{
        background: #ccc;
        border-radius: 10px;
        box-shadow: none;
      }
  }

}


.topic:before {
  font-size: 1rem;
  position: absolute;
  top: -24px;
  left: -2px;
  height: 24px;
  padding: 0 1em;
  content: 'TOPIC';
  color: #fff;
  border-radius: 5px 5px 0 0;
  background: #000;
}


/*スクロールダウン全体の場所*/
.scrolldown3{
    /*描画位置※位置は適宜調整してください*/
  position:absolute;
  bottom:10px;
  right:5%;
    /*マウスの動き1.6秒かけて動く永遠にループ*/
  animation:mousemove 1.6s ease-in-out infinite;
   span{
    position: absolute;
    left:-0.9rem;
    bottom:45px;
    color: rgb(122, 122, 122);
    font-size: 0.7rem;
    letter-spacing: 0.05em;

    &::after{
      content: "";
        /*描画位置*/
      position: absolute;
      top:10px;
      left:17px;
        /*線の形状*/
      width: 1px;
      height: 15px;
      background: rgb(122, 122, 122);
        /*線の動き1.4秒かけて動く。永遠にループ*/
      animation: mousepathmove 1.4s linear infinite;
      opacity:0;
    }

    
    /*上からの距離・不透明度・高さが変化して上から下に流れる*/
    @keyframes mousepathmove{
      0%  {    height:0;    top:10px;    opacity: 0;  }
      50% {    height:15px;              opacity: 1;  }
      100%{    height:0;    top:30px;    opacity: 0;  }
    }
  }
  /*下からの距離が変化して上から下に動く*/
  @keyframes mousemove{
        0%{bottom:10px;}
        50%{bottom:5px;}
      100%{bottom:10px;}
  }
  &:before {
      content: "";
      /*描画位置*/
      position: absolute;
      bottom:0;
      left:-10px;
      /*マウスの形状*/
    width:25px;
    height:37px;
    border-radius: 10px;
    border:1px solid rgb(122, 122, 122);
  }
}


</style>
