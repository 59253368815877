import Vue from 'vue'
import App from './App.vue'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import 'uikit/dist/css/uikit.css'
import 'uikit/dist/css/uikit.min.css'
import router from './router'
//import 'uikit/dist/css/uikit.my-theme.min.css'
import mixin from '@/mixins'
import VueI18n from 'vue-i18n';
import axios from 'axios'
import VueAxios from 'vue-axios'

UIkit.use(Icons)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false
Vue.mixin(mixin) 
Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: 'ja'
});

new Vue({
  router, 
  i18n,
  render: h => h(App),
  mixins: [mixin],

}).$mount('#app')


