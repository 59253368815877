<!-- WorldingのLogoをコネコネする -->
<template>
    <div >
      <router-link to="/">
        <worldingLogo />
      </router-link>
    </div>
</template>

<script>
import WorldingLogo from "@/assets/img/WorldingLogo.svg"

export default {
  components: {
    WorldingLogo,
  },
};
</script>

<style scoped>
svg {
    width: 100%;
    height:100%;
    fill: rgb(92, 92, 92);
    stroke: transparent;
}
 
svg:hover .icon-parts ,svg:hover .moji{
    transform-origin: center;
    animation:  moji 1;
    transform: scale(1.03,1.03) ;
    cursor: pointer;
}
svg:hover .path-d{
     fill: rgb(72, 133, 36);
}

@-webkit-keyframes icon-parts {
  5% {-webkit-transform: translateY(-10%);}
  10% {-webkit-transform: translateY(0);}
  100% {-webkit-transform: translateY(0);}
}
 
@keyframes icon-parts {
  0% {transform:  rotate(0deg);}
  33% {transform: rotate(-10deg) translateX(2%) translateY(8%);}
  66% {transform: rotate(10deg) translateX(-2%) translateY(-8%);}
  100% {transform:  transparent; }
}

svg path.icon-parts {
    /** アニメーション  name | 所要時間 | 減速⇒高速 | 無限に繰り返す */
    -webkit-animation:  icon-parts  4s ease-in infinite;
    animation:  icon-parts  4s ease-in infinite;
    transform-origin:left bottom;
    filter: drop-shadow(1.5px 1.5px 1px rgb(16, 17, 17)) drop-shadow(1px 1px 4px rgba(255, 255, 255, 0.5));
    
}

@keyframes moji {
  0% {stroke-width: 0.1px; stroke:  rgba(70, 70, 70, 0.5);}
  5% {transform: translateY(-5%);}
  10% {transform: translateY(0);fill: rgb(92, 92, 92);}
  40% {stroke-width: 0.1px; stroke: rgba(70, 70, 70, 0.5);}
  60% {transform: translateY(0);fill: rgb(92, 92, 92);}
  70% {
    fill: #eee;
    stroke-dashoffset: 1800;
  }
  83% {   stroke: rgba(119, 119, 119, 0.8);}
  85% { 
    stroke: rgba(255, 255, 255);
    box-shadow: inset 0 10px 25px 0 rgba(255, 255, 255, 0.8);
  }
  90% {
    fill: transparent;
    stroke-dashoffset: 0;
  }
  100% {
    fill: rgba(70, 70, 70, 0.5);
    stroke-dashoffset: 0;
    
  }
}

svg .moji {
    /** アニメーション  name | 所要時間 | 高速⇒減速 | 無限に繰り返す */
  -webkit-animation:  moji 5s ease-in-out infinite;
  animation:  moji 5s ease-in-out infinite;
  transform-origin:left bottom;
  stroke: rgba(107, 107, 107, 0.5);
  stroke-dasharray: 1800;
  stroke-dashoffset: 0;
  stroke-width: 0.3px;
  filter: drop-shadow(1.5px 1.5px 1px rgb(16, 17, 17)) drop-shadow(1px 1px 4px rgba(255, 255, 255, 0.3));
  /* filter: drop-shadow(1.5px 1.5px 1px rgb(16, 17, 17)) drop-shadow(1px 1px 4px rgba(255, 255, 255, 0.5)); */
}

svg path.text-w {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
svg path.text-o {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
svg path.path-r {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
svg path.path-l {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
svg path.path-d {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
svg path.path-i {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
svg path.text-n {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
svg path.text-g {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}


</style>