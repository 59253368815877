import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/pages/Home.vue';
import jigyoTop from '../components/pages/JigyoTop.vue';
import CompanyInfo from '../components/pages/CompanyInfo.vue';
import CompanyAccess from '../components/pages/CompanyAccess.vue';

Vue.use(VueRouter)

const routes = [
  { 
    path: '/',    // 直リンク＆URL用
    name: 'home', // 名前付きルート用
    component: Home,
    meta: { 
      title: 'HOME'
    },
  },
  { path: '/index.html', redirect: '/' },
  {
    path: '/jigyo',
    name: 'jigyo',
    meta: { title: {'ja':'事業概要'  ,'en':'Business'}},
    component: jigyoTop
  },
  // 事業内容------------------------------------------
    {
      path: '/jigyo/logistics',
      name: 'logistics',
      meta: { title: {'ja':'国際物流事業'  ,'en':'Logistics'}},
      component: () => import(/* webpackChunkName: "jigyoLogistics" */ '../components/pages/JigyoLogistics.vue')
    },
    {
      path: '/jigyo/kenki',
      name: 'kenki',
      meta: { 
        title: {'ja':'建設機械販売事業'  ,'en':'Construction machinery'},
        desc: {'ja':'弊社では中古建設機械の買取・販売・修理まで幅広く対応し、取扱い実績は年間500台以上。1級建設機械整備技能士も在籍し、品質・価格ともに自信のある中古建機を取り揃えております。'},
      },
      component: () => import(/* webpackChunkName: "jigyoKenki" */ '../components/pages/JigyoKenki.vue')
    },
    {
      path: '/jigyo/usedCar',
      name: 'car',
      meta: { target: '_blank', title: {'ja':'中古車販売事業'  ,'en':'Used Car'}},
      beforeEnter() {
        window.location = "https://mixyard.co.jp/"
      }
    },
    {
      path: '/jigyo/environment',
      name: 'environment',
      meta: { title: {'ja':'環境事業'  ,'en':'Environment'}},
      component: () => import(/* webpackChunkName: "jigyoEnvironment" */ '../components/pages/JigyoEnvironment.vue')
    },
  // 事業内容------------------------------------------
  {
    path: '/companyInfo',
    name: 'companyInfo',
    meta: { title: {'ja':'会社案内'  ,'en':'Company'}},
    component: CompanyInfo
  },
  {
    path: '/companyAccess',
    name: 'companyAccess',
    meta: { title: {'ja':'アクセス'  ,'en':'Access'}},
    component: CompanyAccess
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: {'ja':'お問い合わせ' ,'en':'Contact'},
      desc: {'ja':'お気軽にお問い合わせください。','en':'Please feel free to contact us.'}
    },
    component: () => import(/* webpackChunkName: "contact" */ '../components/pages/Contact.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router;

