
<template>
  <div v-if="showChild" style="padding: 5rem 2rem;">
      <div :class="'uk-grid-match uk-child-width-1-' + getPageNamesSize + '@m uk-child-width-1-2@s '"
            uk-scrollspy="cls: uk-animation-slide-bottom; target: .uk-inline; delay: 300; repeat: true" uk-grid>
          <div class="uk-inline" v-for="(info, pageName, i) in getPageNames" :key="i" >
              <router-link :to="{name:pageName}" style="height:100%; Width:100%;" :target="info.target">
                <img :src="require('@/assets/img/image/sub-links-jigyo-' + pageName + '.jpg')" class="image-link" alt="">
                <div class="uk-overlay uk-overlay-default uk-position-bottom" style="margin:0 0px 0px 30px; ">
                    <p >{{$_chlang(info.title)}}</p>
                </div>
              </router-link>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'MiniMenu',
  data: () => ({
    showChild: true,
  }),
  computed : {
    getPageNames() {
      return this.$_menu(this.commonSetting.jigyoMenuList);
    },
    getPageNamesSize() {
        return Object.keys(this.getPageNames).length;
    },
  },
  watch :{
    '$i18n.locale': function () {
      this.showChild = false;
      this.$nextTick(() => (this.showChild = true));
    },

  }
}
</script>

<style scoped lang="scss">
.uk-container{
  margin-top:1vh;
  margin-bottom:1vh;
  display: flex;
  align-items: center;
  height:30vh;
  @media screen and (max-width: 960px) {
    height:auto;

    .uk-inline{
      margin-top:5vh;
      height:30vh;
    }
  }
}
a * {
  color: rgb(53, 53, 53);
}

.image-link{
  object-fit: cover;
  width: auto;
  height: 100%;
  
  @media screen and (max-width: 960px) {
    width: 100%;
    height:30vh;
  }
}
</style>
