<!-- Header -->
<template>
  <div class="header-cls" >
    <div class="uk-navbar-container" uk-navbar >
        <div class="uk-navbar-left uk-hidden@m" >
            <a class="uk-navbar-toggle" uk-navbar-toggle-icon uk-toggle="target: #offcanvas-reveal"></a>
        </div>
        <div class="uk-navbar-center " style="height:100%" >
          <logo class="logo" />
        </div>
        <div class="uk-navbar-right " style="height:100% margin:10px" >
          <div class="uk-margin" style="">
            <on-off @child-event="handler($event)"></on-off>
          </div>
        </div>
                  
    </div>
    <div id='lamp' class="uk-animation-fade uk-visible@m" v-if="left>0" :style="{ width: width + 'px', left: left + 'px'}" @mouseover="isLampPointer=true" @mouseout="isLampPointer=false" ></div>
    <ul uk-tab class="tab-menu uk-margin-remove uk-flex-center uk-tab-bottom uk-visible@m" @mouseout="tabNot" >
        <li v-for="(info, pageName, i) of pageNames" :key="i" @mouseover="tabPosition"  :class="{ 'uk-active': isActive(info.path) }">
          <router-link :to="{ name: pageName }">{{$_chlang(info.title)}}</router-link>
        </li>
    </ul>
    <div id="offcanvas-reveal" uk-offcanvas="mode: reveal; overlay: true">
        <div class="uk-offcanvas-bar">
          <button class="uk-offcanvas-close" type="button" uk-close></button>
          <hamburger :pageNames="pageNames" /> 
        </div>
    </div>
  </div>
</template>

<script>
  import Logo from './Logo.vue'
  import OnOff from './OnOff.vue'
  import Hamburger from './Hamburger.vue'

  export default {
    data: () => ({
      drawer: null,
      width:0,
      left:0,
      pageNames:{},
      isLampPointer:false,
      nowUrl:'',
      
    }),
    components: {
      Logo,
      OnOff,
      Hamburger,
    },
    methods: {
      handler(event) {
        this.handleClick_changeLanguage(event.target.checked)
      },
      tabPosition: function(e) {
        var aElement = e.target ;
        if(e.target.tagName != "A"){
          aElement = e.target.getElementsByTagName( "A" )[0] ;
        }
        const rect = aElement.getBoundingClientRect();
        this.width = rect.width;
        this.left = rect.left;

      },
      tabNot() {
        if(!this.isLampPointer){
          const result = this.$el.getElementsByClassName('uk-active')[0].children[0];
          const rect = result.getBoundingClientRect();
          this.width = rect.width;
          this.left = rect.left;
        }

      },
      handleClick_changeLanguage(type) {
        this.$i18n.locale = type ? 'en':'ja';
      }
    },
    watch: {
      '$route': function (to, from) {
        if (to.path !== from.path) {
          this.nowUrl = to.path ;
        }
      }
    },
    mounted () {
      this.$nextTick(function () {
        this.nowUrl = this.$route.path;
      })
    },
    created() {
      // メインメニュー作成
      this.pageNames = this.$_menu(this.commonSetting.menuList);
    },
    computed:{
      isActive(){
        return (page)=>{
          return this.nowUrl == page ? true : ((this.nowUrl).indexOf(page) === 0 && page.length > 1)
        }
      } 
    }
  }
</script>


<style scoped lang="scss">
.uk-offcanvas-reveal, .uk-offcanvas-bar{
  width: 40vw;
  .uk-offcanvas-bar{
    max-width: 100%;
  }
}

.uk-tab {
  border-top: 1px solid  var(--main-color);
  border-bottom: 1px solid  var(--main-color);
  background-color: rgb(255, 255, 255) !important; 
  font-size: 1.2rem;
  .uk-active > a {
    border:  0px;
    color:  var(--main-color);
    font-weight: 600;
    text-align:center;
  }
}
.tab-menu{
  height:35%;
  display: flex;
  align-items: center;
  li * {
    margin: 0 20px;
  }
  li>a {
    display: block;
    text-align: center;
  }
}

.uk-tab-bottom::before,.uk-tab::before{
   border-bottom: 0px; 
}
.uk-navbar-container{
  height:65%;
  @media screen and (max-width: 960px) {
    height:100%;
  }
  background-color: rgb(255, 255, 255) !important; 
}
#lamp {
  height:3px;
  background: var(--main-color);
  display:block;
  position:absolute;
  transition: all .3s ease-in;
  z-index: 99;
}
.logo{
  height: 100%;
}

.header-cls {
    position: sticky;/*固定する*/
    position: -webkit-sticky;/*Safari用ベンダープレフィックス*/
    top: 0;/*ブラウザの上からの距離はゼロ*/
    z-index: 80;
    height:10vh;
}
</style>
