
<template>
  <div id="app">
    <div>
      <div v-if="show" style="height:100vh;">
        <top-open />
      </div>
      <transition name="fade">
        <div v-if="!(show)">
          <header-menu class="header-menu" />
          <router-view class="page-area" />
          <footer-parts />
        </div>
      </transition>

    </div>
  </div>
</template>

<script>

import HeaderMenu from './components/modules/parts/Header.vue'
import FooterParts from './components/modules/parts/Footer.vue'
import TopOpen from "./components/modules/TopOpen.vue"

export default {
  name: 'App',
  components: {
    HeaderMenu,
    FooterParts,
    TopOpen
  },
  data(){
    return {
      show: false,
    }
  },
  watch: {
    // 遷移時 or Reload
    '$route': function (to) {
      this.setDocumentTitle(to.meta);
    },
  },
  methods:{
    // タイトルを設定
    setDocumentTitle(meta){
      const title = (meta.title=='HOME') ? '' : this.$_chlang(meta.title)
      const defaultTitle = this.$_chlang(this.commonSetting.defaultTitle)
      document.title = (title) ? defaultTitle + ' | ' + title : defaultTitle;

      // ディスクリプションを設定
      let setDesc = this.$_chlang(this.commonSetting.defaultDesc);
      if(meta.desc){
        setDesc = this.$_chlang(meta.title);
      }
      document.querySelector("meta[name='description']").setAttribute('content', setDesc)
    }
  },
  mounted: function () {
    this.$nextTick(function () {
        this.setDocumentTitle(this.$route.meta);
        this.show = true;
        setTimeout(() => {
            this.show = false;
        }, 5000);
    })
  }

};
</script>



<style lang="scss">

@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css');
@import url('https://fonts.googleapis.com/css2?family=Klee+One:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Murecho:wght@400;500;600;900&display=swap');

$_mini-size: 640px;
$_tab-size: 960px;
$_lap-size: 1200px;

:root {
  --main-color: #5f7e59;

  --font-family-text:  'Murecho', "SF Pro JP", "SF Pro Text", "SF Pro Icons", "BIZ UDPGothic", Meiryo, sans-serif,cursive;
  --font-family-menu:  'Klee One',sans-serif,cursive;
  font-size: 16px;

  @media screen and (max-width: $_tab-size) {
   font-size: 14px;
  }
  @media screen and (max-width: $_mini-size) {
   font-size: 12px;
  }
}
body {
  font-family: var(--font-family-text);
  font-weight: 400;
  color: #272727;
}
h1,h2,h3,h4,h5,h6{
  font-family: var(--font-family-menu) !important;
}
.desc {
  position: absolute;
  z-index:-5;
}
.contents {
  height: 100%;
  min-height: 100vh;
}
.contents-one {
  height:90vh;
  min-height:90vh;
}
.parallax-bg {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;

  >*a:hover{
    text-decoration: none;
  }
}
.page-title{
  color: rgb(255, 255, 255);
  font-weight: 900;
  text-shadow: 2px 2px 3px #000000,0px 0px 4px #000000,0px 0px 5px #000000;
}
.section-title{
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 2vh 5vw;
  text-shadow: 2px 2px 2px #272727;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: var(--font-family-menu);
}
.section-main{
  background-color: rgba(238, 238, 238, 0.7);
  color: rgb(5, 5, 5);
  padding: 6vh 10vw ;
}

:not(.uk-offcanvas-flip).uk-offcanvas-container-animation{
  left: 0px!important;
}
/* フェードイン・フェードアウト中の設定 */
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
/* フェードイン開始時・フェードアウト終了時の設定 */
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.header-menu{
  position: absolute;
  top:0px;
  left:0px;
  z-index: 2;
  width:100%;
}
.page-area{
  height: 100%;
}

a:hover{
  text-decoration: none !important;
}
/*== ボタン共通設定 */
.btn{
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: inline-block;
  border: 2px solid rgb(27, 27, 27);/* ボーダーの色と太さ */
  padding: 1vh 1vw;
  text-align: center;
  font-weight: 600;
  outline: none;
  transition: ease .2s;

    span {
      position: relative;
      z-index: 3;/*z-indexの数値をあげて文字を背景よりも手前に表示*/
      color:rgb(28, 28, 28);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background:rgba(114, 114, 114, 0.7);/*背景色*/
      width: 100%;
      height: 100%;
      transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top;
    }

    &:hover:before{
      transform-origin:left top;
      transform:scale(1, 1);
    }
    &:hover {
      span{
        color: rgb(229, 229, 229);
      }
    }
}
</style>
